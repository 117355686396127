import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useAggregatedQueries } from 'src/hooks/useAggregatedQuery';
import { Device } from 'src/types/Device';
import { PowerSample, Sample } from 'src/types/Sample';
import { SigfoxTokenState } from 'src/types/SigfoxTokenState';
import { get, post, put, RequestBody } from '../utils';
import { getPropertyInfo } from './legacy-routes';
import { RoomRadarDetails } from 'src/components/device/DeviceRoomRadar';
import { Room, RoomLocation } from 'src/types/Room';
import TetherApi from '../TetherApi';
import { PropertyInfo } from 'src/types/PropertyInfo';
import { getPropertyById } from './property';
import { Property } from 'src/types/Property';

enum QueryKeys {
  Device = 'Device',
  DeviceSample = 'DeviceSample',
  RoomRadarDetails = 'RoomRadarDetails',
  Room = 'Room',
  RoomLocation = 'RoomLocation',
  PropertyInfo = 'PropertyInfo',
  Property = 'Property',
  Circuit = 'Circuit'
}

export function getSigfoxTokenState(deviceId: string): Promise<SigfoxTokenState> {
  return get<SigfoxTokenState>(`device/${deviceId}/sigfoxtokenstate`);
}

export async function getDevice(deviceId: string, options?: { retry: false }): Promise<Device> {
  return new Device(await get<Device>(`device/${deviceId}`, undefined, options?.retry === false ? 3 : 0));
}



export const useGetDevicesById = (deviceIds: string[]) => {
  return useAggregatedQueries<Device>(
    deviceIds.map((deviceId) => ({
      queryKey: [QueryKeys.Device, deviceId],
      queryFn: () => {
        return getDevice(deviceId);
      },
    }))
  );
};


export async function createDevice(device: any): Promise<Device> {
  return post<Device>('device', device);
}

export async function updateDevice(device: Device): Promise<Device> {
  return put<Device>(`device/${device.id}`, device as unknown as RequestBody);
}

export async function getDevices(): Promise<Device[]> {
  return (await get<Device[]>('device')).map((d) => new Device(d));
}

export async function getDevicesByType(type: string): Promise<Device[]> {
  return (await get<Device[]>(`device?type=${type}`)).map((d) => new Device(d));
}

export function getDeviceSamples(deviceId: string, fromTimestamp: string, toTimestamp: string): Promise<Sample[]> {
  const query = {
    fromTimestamp,
    toTimestamp,
  };
  return get<Sample[]>(`device/${deviceId}/samples`, query);
}

export function getHotdropReadings(deviceId: string, fromTimestamp: string, toTimestamp: string): Promise<PowerSample[]> {
  const query = {
    startTimestamp: fromTimestamp,
    endTimestamp: toTimestamp,
  };
  return get<PowerSample[]>(`hotdrop/${deviceId}/readings`, query);
}

export type GetDeviceReadingOptions = {
  milliseconds?: boolean;
};
export type ReadingArray = [number, number][];

export type DeviceReadingResponse = {
  temperature?: ReadingArray;
  humidity?: ReadingArray;
  co2?: ReadingArray;
  pressure?: ReadingArray;
  battery?: ReadingArray;
  light?: ReadingArray;
  dewPoint?: ReadingArray;
  pm1?: ReadingArray;
  pm2_5?: ReadingArray;
  pm10?: ReadingArray;
  flag_co2Calibration?: ReadingArray;
  flag_unknownDeviceType?: ReadingArray;
  totalkWh?: ReadingArray;
  maxCurrent?: ReadingArray;
  minCurrent?: ReadingArray;
  cumulativeActivityCount?: ReadingArray;
  occupied?: ReadingArray;
  value?: ReadingArray;
  water?: ReadingArray;
  maxDwellTime?: ReadingArray;
  count?: ReadingArray;
  averageDwellTime?: ReadingArray;
  payload?: ReadingArray;
};

export async function getDeviceReadings(
  deviceId: string,
  fromTimestamp: string,
  toTimestamp: string,
  opts?: GetDeviceReadingOptions
): Promise<DeviceReadingResponse> {
  const query = {
    fromTimestamp,
    toTimestamp,
    ...opts,
  };

  const readings = await get<DeviceReadingResponse>(`device/${deviceId}/readings`, query);

  // milliseconds isn't on the server yet - so manually do it
  if (query?.milliseconds) {
    Object.keys(readings).forEach((key) => {
      const arr = readings[key] as ReadingArray;

      arr.forEach((pair) => {
        pair[0] *= 1000;
      });
    });
  }

  return readings;
}

export async function getLatestDeviceSample(deviceId: string): Promise<Sample> {
  try {
    const result = await get<Sample>(`device/${deviceId}/samples/latest`);
    return result;
  } catch (_err) {
    return get<Sample>(`device/${deviceId}/latest`);
  }
}

export function useGetLatestDeviceSample(deviceId: string, options?: UseQueryOptions<Sample, AxiosError>) {
  return useQuery(
    [QueryKeys.Device, deviceId, 'latestSample'],
    () => getLatestDeviceSample(deviceId),
    options
  );
}

export function useQueryDevicesByIds(ids: string[], enabled?: boolean) {
  return useAggregatedQueries<Device | null, AxiosError>(
    enabled !== undefined && !enabled
      ? []
      : ids.map((id) => {
        const deviceId = id;
        return {
          queryKey: [QueryKeys.Device, deviceId],
          queryFn: async () => getDevice(deviceId),
        };
      })
  );
}

export function useGetDevice(deviceId: string, options?: UseQueryOptions<Device, AxiosError>): UseQueryResult<Device, AxiosError> {
  return useQuery(
    [QueryKeys.Device, deviceId],
    async () => {
      return getDevice(deviceId);
    },
    options
  );
}

export type DeviceSignal = {
  deviceId: string;
  timestamp: string;
  rssi?: number;
  percentage?: number;

  frequency?: number;
  gatewayNetworkId?: string;
  loraBandwidth?: number;
  loraDataRate?: number;
  loraSpreadingFactor?: number;
  lrrCount?: number;
  snr?: number;
};

export function getLatestDeviceSignal(deviceId: string): Promise<DeviceSignal> {
  return get<DeviceSignal>(`device/${deviceId}/signal`);
}

export function useGetRoomRadarDetails(id: string, options?: UseQueryOptions<RoomRadarDetails, Error>) {
  const { data: roomRadar, isLoading, error } = useQuery([QueryKeys.RoomRadarDetails, id], () => {
    return get<RoomRadarDetails>(`roomradar/${id}`)
  }, options);

  return {
    roomRadar,
    isLoading,
    error
  }
}

export function useGetRoomRadarConfig(id: string, options?: UseQueryOptions<RoomRadarDetails, Error>) {
  const { data: roomRadar, isLoading, error } = useQuery([QueryKeys.RoomRadarDetails, id], () => {
    return get<RoomRadarDetails>(`roomradar/${id}`)
  }, options);

  return {
    roomRadar,
    isLoading,
    error
  }
}

export function useGetRoom(id: string, options?: UseQueryOptions<Room, Error>) {
  const { data: room, isLoading, error } = useQuery([QueryKeys.Room, id], () => {
    return TetherApi.getRoomById(id);
  }, options);

  return {
    room,
    isLoading,
    error
  }
}

export function useGetRoomLocation(id: string, options?: UseQueryOptions<RoomLocation, Error>) {
  const { data: roomLocation, isLoading, error } = useQuery([QueryKeys.RoomLocation, id], () => {
    return TetherApi.getRoomLocationById(id);
  }, options);

  return {
    roomLocation,
    isLoading,
    error
  }
}

interface HotDropProvision {
  hotdropId: string;
  networkType: 'GATEWAY' | 'SPARK';
  backendVersion: 'V2';
  firmwareVersion: 28 | 29;
  sku: string;
}

export function provisionHotdrop(hotDropProvisionSettings: HotDropProvision): Promise<Device> {
  return post<Device>('hotdrop', {
    ...hotDropProvisionSettings,
  });
}

export function useGetPropertyInfo(id: string, options?: UseQueryOptions<PropertyInfo, Error>) {

  const { data: propertyInfo, isLoading, error } = useQuery([QueryKeys.PropertyInfo, id], () => {
    return getPropertyInfo(id);
  }, {
    enabled: Boolean(id),
    staleTime: Infinity,
    ...options,
  });

  return {
    propertyInfo,
    isLoading,
    error
  }
}

export function useGetProperty(id: string, options?: UseQueryOptions<Property, Error>) {

  const { data: property, isLoading, error } = useQuery([QueryKeys.Property, id], () => {
    return getPropertyById(id);
  }, {
    enabled: Boolean(id),
    staleTime: Infinity,
    ...options,
  });

  return {
    property,
    isLoading,
    error
  }
}

