import axios, { AxiosRequestConfig } from 'axios';
import { LogContextValue } from 'src/contexts/LogContext';

const API_HOST = process.env.REACT_APP_API_HOST;
const TIMEOUT = process.env.NODE_ENV === 'development' ? 1000 : 5000;
let log: LogContextValue;

let accessToken = null;

export interface QueryParams {
  [key: string]: string | boolean | number | string[];
}

export interface RequestBody {
  [key: string]: string | boolean | number | string[] | object[];
}

interface HttpHeaders {
  [name: string]: string | string[];
}

export const getHeaders = (): HttpHeaders => ({
  'Content-Type': 'application/json',
  Authorization: accessToken,
});

export class HTTPStatusError extends Error {
  statusCode: number;

  constructor(message: string, statusCode: number) {
    super(message);
    this.statusCode = statusCode;
  }
}

const transformError = (error: any): HTTPStatusError | Error => {
  if (error && error.output && error.output.payload) {
    return new HTTPStatusError(
      `Status ${error.output.payload.statusCode} '${error.output.payload.error}' - ${error.output.payload.message}`,
      error.output.payload.statusCode
    );
  }

  if (error && error.response && error.response.data && error.response.data.error) {
    throw new HTTPStatusError(
      `Status ${error.response.status} '${error.response.data.name}' - ${error.response.data.message}`,
      error.response.status
    );
  }

  throw error;
};

const getFullPath = (path) => {
  if (path.includes('v2')) {
    return `${API_HOST}/${path}`;
  }

  return `${API_HOST}/microservice/${path}`;
};

export const setTetherApiLogContext = (logContext: LogContextValue) => (log = logContext);

export const setAccessToken = (token: string) => {
  accessToken = token;
};

export async function destroy(path: string, queryParams?: QueryParams) {
  const options = {
    headers: getHeaders(),
    params: queryParams,
    TIMEOUT,
  };

  try {
    const response = await axios.delete(getFullPath(path), options);
    return response.data;
  } catch (e) {
    log.debug(`DELETE request to ${path} failed`, e);
    throw transformError(e);
  }
}

export async function get<T>(path: string, queryParams?: QueryParams, attempt: number = 0) {
  const options = {
    headers: getHeaders(),
    params: queryParams,
    TIMEOUT,
  };

  let response;

  try {
    response = await axios.get<T>(getFullPath(path), options);
  } catch (e) {
    if (process.env.NODE_ENV === 'development' && attempt < 3) {
      return get<T>(path, queryParams, attempt + 1);
    }

    if (log) log.debug(`GET request to ${path} failed`, e);
    throw transformError(e);
  }

  return response.data;
}

export async function post<T>(path: string, body?: RequestBody | File, queryParams?: QueryParams, axiosOptions: AxiosRequestConfig = {}) {
  const options = {
    headers: {
      ...getHeaders(),
      ...(axiosOptions.headers ?? {}),
    },
    params: queryParams,
    TIMEOUT,
    ...axiosOptions,
  };

  try {
    const response = await axios.post<T>(getFullPath(path), body, options);
    return response.data;
  } catch (e) {
    if (log) log.debug(`POST request to ${path} failed`, e);
    throw transformError(e);
  }
}

export async function put<T>(path: string, body?: RequestBody) {
  const options = {
    headers: getHeaders(),
    TIMEOUT: 5000,
  };

  try {
    const response = await axios.put<T>(getFullPath(path), body, options);
    return response.data;
  } catch (e) {
    if (log) log.debug(`PUT request to ${path} failed`, e);
    throw transformError(e);
  }
}
